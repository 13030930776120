<template>
	<div class="container" :class="{ 'no-heading-image': !page.headingImage, subpage: page.slug !== 'index' }">
		<Html :lang="locale" />
		<Head v-if="page">
			<Title>{{ page.metaTitle }}</Title>
			<Meta name="description" :content="page.metaDescription" />
			<Meta name="robots" :content="page.metaRobots" />
			<Hreflangs v-if="page.slug !== '404'" :langmenu="page.langmenu" />
		</Head>

		<navigation />

		<slot />

		<pre-footer v-if="page && page.footerSection.length" :footer-section="page.footerSection[0]" />

		<newsletter
			v-if="page && page.showNewsletter"
			:header="defaults[locale].newsletter.title"
			:subtitle="defaults[locale].newsletter.subtitle"
			:content="defaults[locale].newsletter.content"
			:button="defaults[locale].newsletter.button"
			:image="defaults[locale].newsletter.sectionNewsletterImage"
			:image-webp="defaults[locale].newsletter.sectionNewsletterImageWebp"
			:image-alt="defaults[locale].newsletter.sectionNewsletterImageAlt"
		/>
		<footers />

		<pop-up
			v-if="page && defaults[locale].popUp.content"
			:image="defaults[locale].popUp.image"
			:content="defaults[locale].popUp.content"
		/>
		<to-top />
		<cookie-consent v-if="defaults[locale].cookieConsent.published" :item="defaults[locale].cookieConsent" />
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { locale } = useI18n();
const config = useRuntimeConfig();
const route = useRoute();
const { defaults, fetchDefaults } = useDefaults();

await fetchDefaults();
await fetchPage();

const handleScroll = () => {
	const fixedElements = document.querySelectorAll('.fix-on-scroll');
	if (window.scrollY >= 50) {
		fixedElements.forEach((el) => el.classList.add('fixed'));
	} else {
		fixedElements.forEach((el) => el.classList.remove('fixed'));
	}
};

const loadAnimations = () => {
	const fadeElements = document.querySelectorAll('.fade-animate');
	const options = {
		root: null,
		rootMargin: '0px',
		threshold: 0.1,
	};
	const callbacks = (entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				entry.target.classList.add('active');
			}
		});
	};
	const observer = new IntersectionObserver(callbacks, options);
	fadeElements.forEach((element) => {
		observer.observe(element);
	});
};

onBeforeMount(() => {
	window.addEventListener('scroll', handleScroll);
	window.addEventListener('scroll', loadAnimations);
});

onBeforeUnmount(() => {
	window.removeEventListener('scroll', handleScroll);
	window.addEventListener('scroll', loadAnimations);
});

const currentUrl = config.public.siteUrl + route.fullPath;
const { website } = defaults.value[locale.value];
const { metaDescription, metaTitle } = page.value;

useServerSeoMeta({
	twitterCard: metaDescription || '',
	twitterSite: currentUrl || '',
	twitterTitle: metaTitle || '',
	twitterDescription: metaDescription || '',
	twitterCreator: website.hotelName || '',
	twitterImage: website.structuredImage || '',
	ogTitle: metaTitle || '',
	ogUrl: currentUrl || '',
	ogImage: website.structuredImage || '',
	ogDescription: metaDescription || '',
	ogSiteName: website.hotelName || '',
});

useHead({
	script: [
		{
			type: 'application/ld+json',
			children: JSON.stringify({
				'@context': 'http://schema.org',
				'@type': 'Hotel',
				'@id': `${config.public.siteUrl}#hotel`,
				image: website.structuredImage,
				url: config.public.siteUrl,
				name: website.hotelName,
				logo: `${config.public.siteUrl}/images/logo-black.svg`,
				telephone: website.phone,
				email: website.email,
				address: {
					'@type': 'PostalAddress',
					streetAddress: `${website.street} ${website.number}, ${website.city}`,
					postalCode: website.postalCode,
				},
			}),
		},
	],
});
</script>
