<template>
	<div v-if="items.length" v-click-outside="hideMenu" class="lang-select" @click="toggleMenu">
		<span class="flag" :class="locale" />
		<font-awesome-icon icon="fa-light fa-chevron-down" size="1x" />
		<div v-if="showLangNav" class="lang-wrapper">
			<div v-for="item in items" :key="`${item.filename}-${item.language}`">
				<nuxt-link class="flag" :class="item.language" :to="`${item.filename}`" @click="hideMenu">
					<span>{{ item.language }}</span>
				</nuxt-link>
			</div>
		</div>
	</div>
</template>

<script setup>
const { locale } = useI18n();

defineProps({
	items: { type: Array, default: () => [] },
});

const showLangNav = ref(false);

const toggleMenu = () => {
	showLangNav.value = !showLangNav.value;
};

const hideMenu = () => {
	showLangNav.value = false;
};
</script>

<style lang="scss" scoped>
.lang-select {
	align-items: center;
	cursor: pointer;
	display: flex;
	flex-flow: row nowrap;
	font-size: 13px;
	margin: 0 10px;
	padding: 8px 20px;
	position: relative;
	text-transform: uppercase;

	svg {
		font-size: 12px;
		color: #fff;
	}
}

.popup .lang-select {
	padding-right: 0;

	.popup .lang-select svg {
		color: #555;
	}
}

.lang-wrapper {
	position: absolute;
	top: 35px;
	left: 3px;
	right: 3px;
	padding: 6px 9px;
	text-align: center;
	background: #fff;
	box-shadow: 0 18px 18px rgba(0 0 0 / 10%);
	border-radius: 3px;
	z-index: 90;
}

.lang-wrapper.active,
.lang-wrapper a {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	place-content: space-between flex-start;
	color: #000;
	gap: 5px;
}

.lang-wrapper a {
	text-decoration: none;
	width: 100%;
	flex-direction: row;
	gap: 0;
	margin: 8px 0;

	span {
		margin: 3px 0 -3px;
	}
}
</style>
